import * as React from 'react';

import Layout from '../components/layout';

const NotFoundPage = (props) => {
  return (
    <Layout
      pageTitle="404"
      keywords=""
      description="Página não encontrada"
      {...props}
    >
      <div className='content-card'>
        <div className='content-card__body'>
          <h2>404</h2>
          
          <p>Ooops... Página não encontrada.</p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage;